<template>
  <div class="container-fluid bfont">
    <div class="row m- p-4">
      <div class="col-12 m-0 p-4 shadow bg-white rounded">
        <p class="mainfont mb-3">CHG USER</p>
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          show-empty
          outlined
          hover
          small
          @filtered="onFiltered"
          class="font-table"
        ></b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chguser",
  data() {
    return {
      fields: [
        {
          key: "firstname",
          label: "FirstName",
          sortable: true,
        },
        {
          key: "lastname",
          label: "LastName",
          sortable: true,
        },
        {
          key: "usertype",
          label: "User",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "lastlogin",
          label: "LastLogin",
          sortable: true,
        },
        {
          key: "ceratedate",
          label: "Create",
          sortable: true,
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
    };
  },
  mounted() {
    this.getuserchg();
  },
  methods: {
    async getuserchg() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `adminit/getuserchg`,
          {},
          1
        );
        console.log(getAPI.data);
        this.items = getAPI.data;
        // this.$serviceMain.showSuccessAlert(this, "Complete");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
</style>